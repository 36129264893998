.lander {
  background-color: #080808;
  color: #fff;
  font-size: calc(10px + 1vmin);
  text-align: center;
}

.lander.staticBackground {
  background-image: url(../../assets/img/StaticTexture.jpg);
}

.lander a {
  color: inherit;
  text-decoration: none;
}

.videoView {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoView .grabLogo {
  animation: pulseLogo 5s linear infinite;
}

@keyframes pulseLogo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1; 
    /* transform: translateY(-0.5em); */
  }
  100% {
    opacity: 0;
  }
}

.videoView .grabLogo .icon {
  font-size: 3em;
  opacity: 0.08;
}

.video {
  /* width: 100%; */
  height: 100vh;
  width: 100vw;
  min-width: 100vh;
  /* max-width: 190vh; */
  max-width: 240vh;
  top: 0;
  position: fixed;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  transition: opacity 200ms;
}

.showVideo .video {
  opacity: 1;
}

.verticalVideo .video {
  min-width: 0;
  max-width: 75vh;

}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1000;
  min-height: 6em;
  transition: transform 350ms;
}

.footer:not(.show) {
  transform: translateY(6em);
}

.socialButton {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5em;
  transition: width 250ms;
  width: 2.5em;
  margin: 0 0.25em;
}

.socialButton .icon {
  font-size: 1.5em;
}

.socialButton .label {
  position: absolute;
  left: 3em;
  white-space: nowrap;
  font-size: 0.8em;
  opacity: 0;
  transition-delay: 250ms;
  transition: opacity 250ms, transform 250ms;
  transform: translateX(-6em);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

@media (hover: hover) {
  .socialButton:hover {
    width: 8.5em;
  }
  
  .socialButton:hover .label {
    opacity: 1;
    transform: translateX(0);
  }
}
